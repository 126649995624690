import React, { useState } from "react";
import "./style.css";
import axios from "axios";
import { base_url } from "../../constants";
import CryptoJS from "crypto-js";
import { toast } from "react-toastify";
import { handleLogOut } from "../../App";
import { Loader } from "rsuite";
import { WhatsApp, subscripeIcon } from "./svg";
import Modal from "./modal";
import { showToogleTooltib } from "../../store/reducers/tooltibReducer";
import { useDispatch } from "react-redux";

const Subscribe = () => {
  const localData = localStorage.getItem("elmataryapp");
  const decryptedBytes = localData && CryptoJS.AES.decrypt(localData, "111");
  const dispatch = useDispatch();

  const userData =
    decryptedBytes && JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
  const [visible, setVisible] = useState(true);
  const [buyLoading, setBuyLoading] = useState(false);
  const [code, setCode] = useState("");
  const handleBuy = () => {
    if (userData == null) {
      toast.warn("يرجى التسجيل أولاً");
      return;
    }
    if (code == "") {
      toast.warn("أدخل الكود");
      return;
    }
    setBuyLoading(true);
    const data_send = {
      code,
      student_id: userData?.student_id,
      token_value: userData?.token_value,
    };
    axios
      .post(
        base_url + "/user/courses/subscribe_by_code.php",
        JSON.stringify(data_send)
      )
      .then(async (res) => {
        if (res.data.status == "success") {
          toast.success(res.data.message);
          window.location.href = "/studentCourses";
        } else if (res.data.status == "error") {
          dispatch(showToogleTooltib());
          toast.error(res.data.message);
        } else if (res.data.status == "out") {
          localStorage.clear();
          
          // window.location.reload();
        }
      })
      .catch((e) => {
        console.log(e);
        dispatch(showToogleTooltib());
      })
      .finally(() => {
        setBuyLoading(false);
      });
  };
  const dispatchNotCode = () => {
    dispatch(showToogleTooltib());
    toast.error("أدخل كود مكون من 14 رقمًا");
  };
  return (
    <>
      <div class="wrapper-class">
        <div action="#" class="card-content-class">
          <div class="container-class">
            <div class="image-class">
              <i class="fas fa-envelope"></i>
            </div>
            <h1>الاشتراك</h1>
            <h1>انضم للدورات باستخدام كود الاشتراك</h1>
            {/* <h2 class="subscribe__title">
              عزز نفسك بدوراتنا المتطورة
            </h2>
            <p
              class="subscribe__copy"
              style={{
                width: "100%",
                textAlign: "center",
                margin: "0 auto 30px auto",
              }}
            >
              كن في طليعة المعرفة مع دوراتنا الديناميكية وتحديثاتنا الفورية.
            </p> */}
          </div>
          <div class="form-input-class">
            <input
              type="text"
              placeholder="أدخل كود مكون من 14 رقمًا"
              maxLength={14}
              max={14}
              onKeyPress={(e) => {
                const charCode = e.which ? e.which : e.keyCode;
                if (charCode < 48 || charCode > 57) {
                  e.preventDefault();
                }
              }}
              onWheel={(e) => e?.target?.blur()}
              onChange={(e) => {
                const input = e.target.value;
                if (input.length <= 14) {
                  setCode(input); // إعادة تعيين القيمة فقط إذا لم يتجاوز الإدخال 14 حرفًا
                }
              }}
            />

            <br />

            <button
              class="subscribe-btn-class"
              style={{ margin: "30px 0" }}
              onClick={() =>
                buyLoading
                  ? null
                  : code?.length >= 14
                  ? handleBuy()
                  : dispatchNotCode()
              }
            >
              {buyLoading ? <Loader size="md" /> : "انضم الآن"}
            </button>
          </div>
          <p
            style={{
              display: "flex",
              alignItems: "baseline",
              gap: "17px",
              marginTop: "40px",
              justifyContent: "center",
              marginBottom: "0",
              flexWrap: "wrap",
            }}
          >
             <p
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                padding: "5px 14px",
                border: "0.1px solid green",
                cursor: "pointer",

                justifyContent: "center",
                marginBottom: "0",
                borderRadius: "6px",
              }}
              className="greenHover"
              onClick={() =>
                window.open("https://wa.me/+962779787611", "_blanck")
              }
              role="button"
            >
              <span style={{ color: "green", marginBottom: "0" }}>
              +962779787611
              </span>{" "}
              <a
                style={{ fontSize: "23px" }}
                href="https://wa.me/+962779787611"
              >
                {WhatsApp}
              </a>
            </p>
            <span> إذا لم يكن لديك كود اشتراك،تواصل واتساب مع </span>
         
          </p>
        </div>
      </div>
      {/* <Modal visible={visible}>
        <span style={{ margin: "0 auto 20px" }}>{subscripeIcon}</span>
        <h1 style={{ textAlign: "center" }}>تعليمات الاشتراك!</h1>
        <p
          style={{
            textAlign: "center",
            fontSize: "15px",
            width: "70%",
            margin: "auto",
            // color: "darkgrey",
          }}
        >
          كود الاشتراك المكون من 14 رقمًا يُستخدم للانضمام إلى جميع الدورات - تحتاج إلى كود واحد فقط
        </p>
        <div style={{ marginTop: "70px", textAlign: "center " }}>
          <p
            style={{
              display: "flex",
              alignItems: "baseline",
              gap: "17px",
              marginTop: "auto",
              marginBottom: "0",
              textAlign: "center",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <span> إذا لم يكن لديك كود اشتراك، اتصل بـ </span>
            <p
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                padding: "5px 14px",
                border: "0.1px solid green",
                cursor: "pointer",
                justifyContent: "center",
                borderRadius: "6px",
              }}
              className="greenHover"
              onClick={() =>
                window.open("https://wa.me/+962779787611", "_blanck")
              }
              role="button"
            >
              <span style={{ color: "green" }}>+962779787611</span>{" "}
              <a
                style={{ fontSize: "23px" }}
                href="https://wa.me/+962779787611"
              >
                {WhatsApp}
              </a>
            </p>
          </p>
          <p
            style={{
              display: "flex",
              alignItems: "baseline",
              gap: "17px",
              marginTop: "auto",
              marginBottom: "0",
              textAlign: "center",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <span> إذا كان لديك الكود بالفعل </span>

            <button
              className="btn btn-success"
              onClick={() => setVisible(false)}
            >
              المتابعة للاشتراك
            </button>
          </p>
        </div>
      </Modal> */}
    </>
  );
};

export default Subscribe;
