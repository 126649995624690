import { useEffect, useState } from "react";
import { AccordionCustomIcon } from "../Accordian/Accordian";
import styles from "./TableofContent.module.css";
import CryptoJS from "crypto-js";
import "../../style.css";
import { useNavigate, useSearchParams } from "react-router-dom";
export default function TableofContent({
  handleSelectVideo,
  videoObj,
  handlePrevVideo,
  handleNextVideo,
  showContent,
  Videos,
  setTab,
  setIndex,
  index,
  tab,
  selectedVideo,
}) {
  const [link, setLink] = useState();
  const [params] = useSearchParams();
  const [player, setPlayer] = useState(
    videoObj?.link_video && videoObj?.link_video?.length ? 1 : 2
  );
  useEffect(() => {
    setPlayer(videoObj?.link_video && videoObj?.link_video?.length ? 1 : 2);
  }, [videoObj]);
  const players = [
    videoObj?.link_video &&
      videoObj?.link_video?.length && {
        id: 1,
        label: "Player 1",
      },
    { id: 2, label: "Player 2" },
  ];
  const getYoutibeUrl = (youtube_id) => {
    if (youtube_id && youtube_id.length) {
      const regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = youtube_id.match(regExp);
      if (match && match[2].length === 11) {
        setLink(match[2]);
      }
    }
  };
  const localData = localStorage.getItem("elmataryapp");
  const decryptedBytes = localData && CryptoJS.AES.decrypt(localData, "111");
  const userData =
    decryptedBytes && JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
  useEffect(() => {
    if (player == 1) {
      getYoutibeUrl(videoObj?.link_video);
    } else {
      setLink(videoObj?.loom_url?.split("share/")[1]);
    }
  }, [player, videoObj]);
  const navigate = useNavigate();
  const [position, setPosition] = useState({
    top: "10px",
    left: "10px",
    opacity: 0.5,
  });

  useEffect(() => {
    const updatePosition = () => {
      // Generate random positions within the bounds of the video frame
      const randomTop = Math.floor(Math.random() * 80) + "%";
      const randomLeft = Math.floor(Math.random() * 80) + "%";
      setPosition({ top: randomTop, left: randomLeft, opacity: 0.5 });
      const timeOut = setTimeout(
        () => setPosition({ ...position, opacity: 0 }),
        2500
      );
    };

    // Update position every 3 seconds
    const interval = setInterval(updatePosition, 6000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);
  return (
    <div className="videoscolumnDiv">
      <div
        className="players tabs"
        style={{
          margin: "20px",
          display: "flex",
          gap: "20px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* {players?.map((item) => {
          return item?.label && item?.label?.length ? (
            <button
              className={player == item?.id ? "btn btn-success" : "btn"}
              onClick={() => setPlayer(item?.id)}
            >
              {item?.label}
            </button>
          ) : null;
        })} */}
      </div>
      <div
        className={`${styles.container} my-8 videoContentTwo`}
        style={{ width: "100%", minHeight: "100vh" }}
      >
        <div className={styles.video + " showVideo"}>
          {player == 1 ? (
            <iframe
              // width="560"
              className="publitioPlaceHolder"
              height="415"
              src={`https://www.youtube.com/embed/${link}?autoplay=0&cc_lang_pref=en-GB&cc_load_policy=1&controls=2&rel=0&hl=en-GB&enablejsapi=1&origin=https%3A%2F%2Fsupport.google.com&widgetid=1&fs=1`}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; allowfullscreen;fullscreen;"
              allowfullscreen
              onContextMenu={(e) => e.preventDefault()}
            ></iframe>
          ) : player == 2 ? (
            // eslint-disable-next-line jsx-a11y/iframe-has-title
            <div className="videoTest">
              <div
                className="student_email"
                style={{
                  position: "absolute",
                  top: position.top,
                  left: position.left,
                  transition: "top 0.5s, left 0.5s",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  color: "white",
                  padding: "5px",
                  borderRadius: "5px",
                  opacity: position?.opacity
                }}
              >
                {userData?.student_email}
              </div>
              {/* <div
                className="student_id"
                style={{
                  position: "absolute",
                  top: position.top,
                  right: position.left,
                  transition: "top 0.5s, left 0.5s",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  color: "white",
                  padding: "5px",
                  borderRadius: "5px",
                }}
              >
                {userData?.student_id}
              </div> */}
              <iframe
                src={"https://www.loom.com/embed/" + link}
                // width="560"
                height="415"
                frameborder="0"
                webkitallowfullscreen
                mozallowfullscreen
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; allowfullscreen;fullscreen;"
                allowfullscreen={true}
              ></iframe>
            </div>
          ) : null}
        </div>

        {showContent ? (
          <div className={`${styles.accordian}`}>
            <div className="progress-container" style={{ paddingLeft: "30px" }}>
              <span onClick={handlePrevVideo} className="handlePrevVideo">
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 my-auto"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5"
                  />
                </svg>
              </span>
              <div className="progressBar">
                <p style={{ fontWeight: "800" }}>{params.get("course_name")}</p>
                <div className="progress">
                  <span
                    style={{
                      width: params.get("r") + "%",
                      display: "block",
                    }}
                  ></span>
                </div>
                <p>
                  <span>Process</span>
                  <span>{params.get("r")}%</span>
                </p>
              </div>
              <span onClick={handleNextVideo}>
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 my-auto"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5"
                  />
                </svg>
              </span>
            </div>
            <AccordionCustomIcon
              handleSelectVideo={handleSelectVideo}
              Videos={Videos}
              setTab={setTab}
              selectedVideo={selectedVideo}
              setIndex={setIndex}
              index={index}
              tab={tab}
              videoObj={videoObj}
            />
          </div>
        ) : null}
      </div>
      {/* <button
        onClick={() => {
          navigate("/videoquestions", {
            state: { video: videoObj },
          });
        }}
        style={{ marginRight: "4px", marginBottom:"20px", marginTop:"20px", color: "white" }}
        className="btn btn-warning"
      >
        questions
      </button> */}
    </div>
  );
}
