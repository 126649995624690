import "./Quiz.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

export default function Quiz({ data, timer }) {
  const navigate = useNavigate();
  const [dataType] = useSearchParams();
  const [questionIndex, setQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState(Array(data.length).fill(null));
  const [submittedAnswers, setSubmittedAnswers] = useState(
    Array(data.length).fill(false)
  );
  const [correctAnsIndex, setCorrectAnsIndex] = useState(0);
  const [correctAnswer, setCorrectAnswer] = useState("");
  const [wrongAnswers, setAllWrongAnswers] = useState([]);
  const [timeLeft, setTimeLeft] = useState(timer * 60); // timer in minutes converted to seconds

  function handleNextQuestion() {
    if (questionIndex === data.length - 1) {
      return;
    }
    setQuestionIndex((prevState) => prevState + 1);
  }

  function handlePrevQuestion() {
    if (questionIndex === 0) {
      return;
    }
    setQuestionIndex((prevState) => prevState - 1);
  }

  function handleChooseAnswer(index) {
    const newAnswers = [...answers];
    newAnswers[questionIndex] = index;
    setAnswers(newAnswers);
  }

  function handleSubmitAnswers() {
    if (answers[questionIndex] !== null) {
      const newSubmittedAnswers = [...submittedAnswers];

      if (data[questionIndex].real_answers[answers[questionIndex]].answer_check) {
        setCorrectAnsIndex((prevState) => prevState + 1);
      }

      newSubmittedAnswers[questionIndex] = true;
      setSubmittedAnswers(newSubmittedAnswers);
      setCorrectAnswer(data[questionIndex].question_valid_answer);
      setAllWrongAnswers(
        data[questionIndex].real_answers
          .filter((opt) => !opt.answer_check)
          .map((opt) => opt.answer_text)
      );
    }
  }

  function handleQuestionClick(index) {
    setQuestionIndex(index);
  }

  useEffect(() => {
    if (dataType.get("type") !== "unit") {
      if (timeLeft > 0) {
        const timerId = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
        return () => clearTimeout(timerId);
      } else {
        navigate(`/finalResult/${correctAnsIndex}`);
      }
    }
  }, [timeLeft, navigate, correctAnsIndex, dataType]);

  useEffect(() => {
    if (submittedAnswers[questionIndex]) {
      setCorrectAnswer(data[questionIndex].question_valid_answer);
      setAllWrongAnswers(
        data[questionIndex].real_answers
          .filter((opt) => !opt.answer_check)
          .map((opt) => opt.answer_text)
      );
    } else {
      setCorrectAnswer("");
      setAllWrongAnswers([]);
    }
  }, [questionIndex, submittedAnswers, data]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  const getPaginationButtonColor = (index) => {
    if (index === questionIndex) {
      return "#ffc107"; // Yellow for active question
    }
    if (submittedAnswers[index]) {
      if (answers[index] !== null && data[index].real_answers[answers[index]].answer_check) {
        return "#28a745"; // Green for correct answers
      } else {
        return "#dc3545"; // Red for wrong answers
      }
    }
    return "#6c757d"; // Grey for unanswered questions
  };

  return (
    <div style={{ padding: "20px" }}>
      {timer && <div className="timer">Time Left: {formatTime(timeLeft)}</div>}
      <div className="examQuestion_container">
        <p style={{ marginLeft: "auto" }}>
          {questionIndex + 1}/{data.length}
        </p>
        <h2 style={{ fontWeight: "semibold" }}>
          {questionIndex + 1}- {data[questionIndex]?.question_text}
        </h2>
        <ul
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            listStyleType: "none",
            marginTop: "10px",
          }}
        >
          {data[questionIndex]?.real_answers?.map((option, index) => (
            <button
              disabled={submittedAnswers[questionIndex]}
              key={option.answer_text}
              className={
                submittedAnswers[questionIndex] &&
                option.answer_check
                  ? "correct_answer"
                  : submittedAnswers[questionIndex] &&
                    index === answers[questionIndex] &&
                    !option.answer_check
                  ? "wrong_answer"
                  : ""
              }
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                padding: "10px",
                border: "1px solid #27be9f",
                borderRadius: "12px",
                backgroundColor: "white",
              }}
              onClick={() => {
                handleChooseAnswer(index);
              }}
            >
              <input
                type="radio"
                name={`answer-${questionIndex}`}
                id={option.answer_text}
                value={option.answer_text}
                checked={index === answers[questionIndex]}
                readOnly
              />
              <label style={{ fontSize: "14px" }} htmlFor={option.answer_text}>
                {option.answer_text}
              </label>
            </button>
          ))}
        </ul>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <button
            style={{
              color: "white",
              backgroundColor: "#27be9f",
              padding: "7px 20px",
              border: "1px solid #27be9f",
              borderRadius: "10px",
            }}
            className={questionIndex === 0 ? "disabled" : ""}
            disabled={questionIndex === 0}
            onClick={handlePrevQuestion}
          >
            Prev
          </button>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            {submittedAnswers[questionIndex] &&
              questionIndex !== data.length && (
                <button
                  style={{
                    color: "white",
                    backgroundColor: "#27be9f",
                    padding: "7px 20px",
                    border: "1px solid #27be9f",
                    borderRadius: "10px",
                  }}
                  onClick={()=> questionIndex !== data.length -1 ? handleNextQuestion() : navigate(`/finalResult/${correctAnsIndex}`)}
                >
                  Next
                </button>
              )}
            {!submittedAnswers[questionIndex] &&
              questionIndex !== data.length&& (
                <button
                  style={{
                    color: "white",
                    backgroundColor: "#27be9f",
                    padding: "7px 20px",
                    border: "1px solid #27be9f",
                    borderRadius: "10px",
                  }}
                  onClick={()=> questionIndex !== data.length ? handleSubmitAnswers() : navigate(`/finalResult/${correctAnsIndex}`)}
                >
                  Submit
                </button>
              )}
            {questionIndex === data.length && (
              <button
                style={{
                  color: "white",
                  backgroundColor: "#27be9f",
                  padding: "7px 20px",
                  border: "1px solid #27be9f",
                  borderRadius: "10px",
                }}
                onClick={() => {
                  navigate(`/finalResult/${correctAnsIndex}`);
                }}
              >
                Finish
              </button>
            )}
          </div>
        </div>
        <div style={{ marginTop: "20px" }}>
  {correctAnswer && (
    <>
      {/* <h2>
        Correct Answer:{" "}
        <span style={{ color: "#27be9f" }}>{correctAnswer}</span>
      </h2> */}
      {/* <p>
        Explanation:{" "}
        {data[questionIndex].real_answers.find(
          (answer) => answer.answer_text === correctAnswer
        )?.answer_exp}
      </p> */}
    </>
  )}

  {wrongAnswers?.map((opt, i) => (
    <div key={i}>
      {/* <h2>
        Answer: <span style={{ color: "#dc3545" }}>{opt}</span>
      </h2> */}
      {/* <p>
        Explanation:{" "}
        {data[questionIndex].real_answers.find(
          (answer) => answer.answer_text === opt
        )?.answer_exp}
      </p> */}
    </div>
  ))}
</div>

        <div className="pagination">
          {submittedAnswers.map((submitted, index) => (
            <button
              key={index}
              style={{
                margin: "5px",
                padding: "5px 10px",
                borderRadius: "5px",
                backgroundColor: getPaginationButtonColor(index),
                color: "white",
                border: "1px solid #27be9f",
              }}
              onClick={() => handleQuestionClick(index)}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
