import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router";
import "./courses.css";
import { coursesTypesData } from "./data";
import CryptoJS from "crypto-js";
import Skeleton from "react-loading-skeleton";
import { getCourses } from "../../AllCourses/functions/getAll";
import logo from "../../../assets/logo/medLearningHub.png";
import { MdPlayLesson } from "react-icons/md";

const Courses = () => {
  const localData = localStorage.getItem("elmataryapp");
  const decryptedBytes = localData && CryptoJS.AES.decrypt(localData, "111");
  const userData =
    decryptedBytes && JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));

  const navigate = useNavigate();
  const [coursesData, setCoursesData] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [pageLoading, setPageLoading] = useState(true);
  const [originalCourses, setOriginalCourses] = useState([]);

  const getCoursesType = useCallback(() => {
    setSelectedType(coursesTypesData[0].id);
  }, []);

  const filterCourses = useCallback(() => {
    if (selectedType === 0) {
      setCoursesData(originalCourses);
    } else {
      setCoursesData(
        originalCourses.filter((item) => item.type_id === selectedType)
      );
    }
  }, [selectedType, originalCourses]);

  useEffect(() => {
    // if (userData) {
    getCourses(
      userData,
      setPageLoading,
      setCoursesData,
      setOriginalCourses,
      null,
      "home"
    );
    getCoursesType();
    // }
  }, []);

  useEffect(() => {
    if (selectedType !== "") {
      filterCourses();
    }
  }, [selectedType, filterCourses]);

  return (
    <div className="courses_component" style={{ marginBottom: "40px" }}>
      {/* {userData && ( */}
      <>
        {coursesData.length > 0 && (
          <div className="columnDiv" style={{ marginBottom: "40px" }}>
            <h1>Free Courses</h1>
           
          </div>
        )}

        <div className="courses">
          {coursesData.length > 0 ? (
            coursesData
              ?.filter((item) => item?.free == "1")
              ?.map((item, index) => (
                <div
                  key={item.course_id}
                  className="course-card"
                  onClick={() => {
                    navigate(
                      item?.free == "1"
                        ? "/CourseContent?course_id=" + item?.course_id
                        : "/supscripe",
                      { state: { course: item } }
                    );
                  }}
                >
                  {/* <div className="course-price">
                    {item?.course_price == 0 ? "Free" : item?.course_price}
                  </div> */}
                  <div class="main">
                    <img
                      class="tokenImage"
                      src={item?.course_photo_url}
                      alt=""
                    />
                    {/* <div className="course-title">
                      <h2>Surgery</h2>
                    </div> */}

                    <div className="course-content-body">
                      <h4>{item?.course_name}</h4>
                      <p class="description">
                        {item?.course_content?.length > 50
                          ? item?.course_content?.substring(0, 50) + "..."
                          : item?.course_content}
                      </p>
                    </div>
                  </div>
                </div>
              ))
          ) : (
            <div className="empty">
              <MdPlayLesson className="icon" />
              <h5>No Courses</h5>
            </div>
          )}
        </div>
      </>
      {/* )} */}

      {/* {!userData && (
        <div style={{ textAlign: "center" }}>
          <img
            style={{ width: "500px", maxWidth: "90%" }}
            src={require("../../../assets/sig.jpg")}
            alt=""
          />
          <h4 className="text-center">Please Register First</h4>
          <p>To See All Courses</p>
          <button
            onClick={() => {
              navigate("/signup");
            }}
            className="btn btn-primary mt-2"
          >
            Sign Up
          </button>
        </div>
      )} */}

      {coursesData.length > 0 && (
        <button onClick={() => navigate("/allcourses")} className="btn fourth">
          View all our courses
        </button>
      )}

      <hr className="hr" />
    </div>
  );
};

export default Courses;
